import React, { useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import Button from '@atlaskit/button/new';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useIsImportersOnProjectDirectoryEnabled } from '@atlassian/jira-importers-discovery/src/controllers/use-is-importers-on-project-directory-enabled/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { ProjectCreateDrawerConsumer } from '@atlassian/jira-project-create-drawer-context';
import type { OpenDrawerMethod } from '@atlassian/jira-project-create-drawer-context/src/types';
import type { importProjectAction_projectsDirectoryV3_ImportButtonWrapper$key } from '@atlassian/jira-relay/src/__generated__/importProjectAction_projectsDirectoryV3_ImportButtonWrapper.graphql';
import messages from './messages';

type Props = {
	dataRef: importProjectAction_projectsDirectoryV3_ImportButtonWrapper$key;
};

const ImportButton = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const shouldShowImportButton = useIsImportersOnProjectDirectoryEnabled();

	const handleImportButtonClick = useCallback(
		(open: OpenDrawerMethod) => {
			open({
				isNextGen: true,
				showExperienceSelection: false,
				source: 'importDiscoveryProjectsDirectoryCTA',
				isImportProjectOpenInitially: true,
			});
			fireUIAnalytics(
				createAnalyticsEvent({}),
				'button clicked',
				'importDiscoveryProjectsDirectoryCTA',
			);
		},
		[createAnalyticsEvent],
	);

	return (
		shouldShowImportButton && (
			<ProjectCreateDrawerConsumer>
				{({ methods: { open } }) => (
					<Button onClick={() => handleImportButtonClick(open)}>
						{formatMessage(messages.importProject)}
					</Button>
				)}
			</ProjectCreateDrawerConsumer>
		)
	);
};

const ImportButtonWrapper = ({ dataRef }: Props) => {
	const data = useFragment<importProjectAction_projectsDirectoryV3_ImportButtonWrapper$key>(
		graphql`
			fragment importProjectAction_projectsDirectoryV3_ImportButtonWrapper on JiraQuery {
				canCreateProject: canPerform(type: CREATE_PROJECT, cloudId: $cloudId)
					@optIn(to: "JiraAction")
			}
		`,
		dataRef,
	);

	if (!data?.canCreateProject) {
		return null;
	}

	return <ImportButton />;
};

const ImportButtonContainer = (props: Props) => (
	<JSErrorBoundary id="import-project-button" packageName="jiraProjectsDirectoryV3" fallback="flag">
		<ImportButtonWrapper {...props} />
	</JSErrorBoundary>
);

export { ImportButtonContainer as ImportButton };
