import React, { useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import {
	FavoriteCellOld as BaseFavoriteCellOld,
	FavoriteCell as BaseFavoriteCell,
} from '@atlassian/jira-directory-base-v3/src/ui/table-cells/favourite-cell';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { favouriteCell_projectsDirectoryV3_FavoriteCell$key } from '@atlassian/jira-relay/src/__generated__/favouriteCell_projectsDirectoryV3_FavoriteCell.graphql';

type Props = {
	project: favouriteCell_projectsDirectoryV3_FavoriteCell$key;
};

export const FavoriteCell = ({ project }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({
		action: 'clicked',
		actionSubject: 'resultsTable',
	});

	const data = useFragment<favouriteCell_projectsDirectoryV3_FavoriteCell$key>(
		graphql`
			fragment favouriteCell_projectsDirectoryV3_FavoriteCell on JiraProject {
				canViewIssue: action(type: VIEW_ISSUES) {
					canPerform
				}
				...favouriteCell_directoryBaseV3_FavoriteCell_queryRef
				id
				favouriteValue {
					...favouriteCell_directoryBaseV3_FavoriteCellOld_favouriteValueRef
				}
				name
			}
		`,
		project,
	);

	const handleClick = useCallback(() => {
		fireUIAnalytics(analyticsEvent, 'FavoriteButton');
	}, [analyticsEvent]);

	if (getWillShowNav4()) {
		return !data.canViewIssue?.canPerform ? null : (
			<BaseFavoriteCell queryRef={data} onClick={handleClick} />
		);
	}

	// Delete the following during getWillShowNav4 cleanup
	const { id, name, favouriteValue, canViewIssue } = data;

	if (!canViewIssue?.canPerform || !favouriteValue) {
		return null;
	}

	return (
		<BaseFavoriteCellOld
			id={id}
			favoriteItemName={name}
			favouriteValueRef={favouriteValue}
			onClick={handleClick}
		/>
	);
};
