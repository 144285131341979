import React, { type ReactNode } from 'react';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { Link as RouterLink } from '@atlassian/react-resource-router';

type LinkProps = {
	children: ReactNode;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	createAnalyticsEvent?: any;
	[key: string]: unknown;
};

function Link(props: LinkProps) {
	const { children, ...rest } = props;

	delete rest.createAnalyticsEvent;

	return <RouterLink {...rest}>{children}</RouterLink>;
}

export default ComponentWithAnalytics('link', {
	onClick: 'clicked',
})(Link);
