import React from 'react';
import AkButton from '@atlaskit/button';
import CloseIcon from '@atlaskit/icon/core/migration/close--cross';
import SearchIcon from '@atlaskit/icon/core/migration/search';
import Search from '@atlaskit/icon/core/search';
import { Box, media, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

type PropsSearchFieldIconDynamic = {
	hasValue: boolean;
	searchIconVisible: boolean;
	clearIconLabel: string;
	// @deprecated remove when cleaning up visual-refresh FG
	primaryColor?: string;
	onClear: () => void;
	onlyIconSpacing?: boolean;
};

/**
 * Provides a dynamic icon within a search field that toggles between a search and clear icon based on user input, allowing for an interactive and responsive search experience.
 */
export const SearchFieldIconDynamic = ({
	onClear,
	hasValue,
	searchIconVisible,
	clearIconLabel,
	primaryColor,
	onlyIconSpacing = false,
}: PropsSearchFieldIconDynamic) => {
	const akSearchIcon = (
		<SearchIcon
			LEGACY_size="small"
			label=""
			LEGACY_primaryColor={primaryColor}
			color="currentColor"
		/>
	);
	const searchIcon = searchIconVisible ? akSearchIcon : <></>;
	return (
		<Box xcss={[wrapperStyles, onlyIconSpacing && spacingStyles]}>
			{hasValue ? (
				<AkButton
					appearance="subtle"
					spacing="none"
					data-test-id="searchfield.ui.searchfield-icon.clear-button"
					testId="searchfield.ui.searchfield-icon.clear-button"
					onClick={onClear}
					iconBefore={
						<CloseIcon
							LEGACY_size="small"
							label={clearIconLabel}
							LEGACY_primaryColor={primaryColor}
							color="currentColor"
						/>
					}
				/>
			) : (
				searchIcon
			)}
		</Box>
	);
};

type PropsSearchFieldIcon = {
	searchIconVisible: boolean;
	onlyIconSpacing?: boolean;
};

export const SearchFieldIcon = ({
	searchIconVisible,
	onlyIconSpacing = false,
}: PropsSearchFieldIcon) => (
	<Box xcss={[wrapperStylesBefore, onlyIconSpacing && spacingStylesBefore]}>
		{searchIconVisible ? <Search spacing="none" label="" color={token('color.icon')} /> : <></>}
	</Box>
);

const wrapperStyles = xcss({
	color: 'color.text.accent.gray',
	marginTop: 'space.0',
	marginRight: 'space.100',
	marginBottom: 'space.0',
	marginLeft: 'space.0',
	height: '16px',
	display: 'flex',
});

const spacingStyles = xcss({
	marginRight: 'space.075',
	[media.above.md]: {
		marginRight: 'space.100',
	},
});

const wrapperStylesBefore = xcss({
	display: 'flex',
	marginRight: 'space.0',
	marginLeft: 'space.075',
	[media.above.md]: {
		marginLeft: 'space.100',
	},
});

const spacingStylesBefore = xcss({
	marginLeft: 'space.075',
	[media.above.md]: {
		marginLeft: 'space.100',
	},
});
