/**
 * @generated SignedSource<<dbf295a1dcfe2314af7355f6a5b25b36>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type importProjectAction_projectsDirectoryV3_ImportButtonWrapper$data = {
  readonly canCreateProject: boolean | null | undefined;
  readonly " $fragmentType": "importProjectAction_projectsDirectoryV3_ImportButtonWrapper";
};
export type importProjectAction_projectsDirectoryV3_ImportButtonWrapper$key = {
  readonly " $data"?: importProjectAction_projectsDirectoryV3_ImportButtonWrapper$data;
  readonly " $fragmentSpreads": FragmentRefs<"importProjectAction_projectsDirectoryV3_ImportButtonWrapper">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "importProjectAction_projectsDirectoryV3_ImportButtonWrapper",
  "selections": [
    {
      "alias": "canCreateProject",
      "args": [
        {
          "kind": "Variable",
          "name": "cloudId",
          "variableName": "cloudId"
        },
        {
          "kind": "Literal",
          "name": "type",
          "value": "CREATE_PROJECT"
        }
      ],
      "kind": "ScalarField",
      "name": "canPerform"
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "98b0e2d801b8997c0c6435649837fd2f";

export default node;
