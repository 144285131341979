/**
 * @generated SignedSource<<614bdfca35075e4c4f5b51d96d2d8427>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type actionsCell_projectsDirectoryV3_project$data = {
  readonly canEdit: {
    readonly canPerform: boolean;
  } | null | undefined;
  readonly canViewProjectConfig: {
    readonly canPerform: boolean;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"actionsCell_projectsDirectoryV3_ActionsCellDropdown_project">;
  readonly " $fragmentType": "actionsCell_projectsDirectoryV3_project";
};
export type actionsCell_projectsDirectoryV3_project$key = {
  readonly " $data"?: actionsCell_projectsDirectoryV3_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"actionsCell_projectsDirectoryV3_project">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "name": "canPerform"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "actionsCell_projectsDirectoryV3_project",
  "selections": [
    {
      "alias": "canEdit",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "EDIT_PROJECT_CONFIG"
        }
      ],
      "concreteType": "JiraProjectAction",
      "kind": "LinkedField",
      "name": "action",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
    },
    {
      "alias": "canViewProjectConfig",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "VIEW_PROJECT_CONFIG"
        }
      ],
      "concreteType": "JiraProjectAction",
      "kind": "LinkedField",
      "name": "action",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "action(type:\"VIEW_PROJECT_CONFIG\")"
    },
    {
      "kind": "FragmentSpread",
      "name": "actionsCell_projectsDirectoryV3_ActionsCellDropdown_project"
    }
  ],
  "type": "JiraProject"
};
})();

(node as any).hash = "9bbdd28e963158cad6040ea1b67b1dce";

export default node;
