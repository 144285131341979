import React, { forwardRef, type MutableRefObject } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types';
import { useIntl } from '@atlassian/jira-intl';
import { shouldShowTrashProjectTrigger } from '@atlassian/jira-project-restore-utils/src/utils/show-trash-trigger/index.tsx';
import messages from './messages';

type Props = {
	projectType: ProjectType;
	isAdmin: boolean;
	canAdministerProject: boolean;
	isSimplified: boolean;
	onClick: () => void;
};

export const TrashProjectDropdownItem = forwardRef(
	(
		{ onClick, ...args }: Props,
		ref: MutableRefObject<HTMLElement | null> | ((instance: HTMLElement | null) => void) | null,
	) => {
		const { formatMessage } = useIntl();

		if (!shouldShowTrashProjectTrigger(args)) {
			return null;
		}

		return (
			<DropdownItem ref={ref} onClick={onClick}>
				{formatMessage(messages.delete)}
			</DropdownItem>
		);
	},
);
