import React, { type ComponentType } from 'react';
import { lazy } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { isLoaderErrorAttributes } from '@atlassian/jira-errors-handling/src/utils/is-loader-error-attributes.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { ProfileCardProps } from './main';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { AnalyticsData, Actions, ProfilecardTriggerPosition, TriggerType } from './types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	VIEW_PROFILE_ACTION,
	ASSIGNED_ISSUE_ACTION,
	REPORTED_ISSUE_ACTION,
	VIEW_FILTERS_ACTION,
} from './common/constants';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyComponent = lazy<ComponentType<ProfileCardProps>>(() =>
	import(/* webpackChunkName: "async-profile-card-next" */ './main').then(
		(module) => module.ProfileCard,
	),
);

export const AsyncProfileCardNext: ComponentType<ProfileCardProps> = (props) => (
	<JSErrorBoundary
		id="AsyncProfileCardNext"
		packageName="jiraProfilecardNext"
		render={() => (
			// props.children in this context is the code being wrapped
			// in a <ProfileCardTrigger> (I.e. it is the thing a user hovers over to
			// display a profile card).
			// In the event of an unexpected error within @atlaskit/profilecard,
			// just render the unwrapped children (I.e. they will render but nothing
			// happens on hover)
			<>{props.children}</>
		)}
		sendToPrivacyUnsafeSplunk
		teamName="empanada"
		attributes={isLoaderErrorAttributes}
	>
		<Placeholder name="async-profile-card-next-view" fallback={props.children}>
			<LazyComponent {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

export default AsyncProfileCardNext;
