import log from '@atlassian/jira-common-util-logging/src/log';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { AdminQuickSearchResponse } from '../../common/types';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default async (): Promise<AdminQuickSearchResponse | null> => {
	try {
		return await fetchJson('/rest/adminquicksearch/latest/links/default');
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.safeErrorWithoutCustomerData('shortcuts-dialog.fetch-admin-links', error.toString());
		return null;
	}
};
