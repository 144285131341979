import React, { useMemo } from 'react';
import { css, styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';
import { type SortProps, type HeadType, type RowType, SortOrder } from './types';
import { TableHead } from './ui/table-head';
import { TableRow } from './ui/table-row';

export interface Props extends SortProps {
	head: HeadType;
	rows: RowType[];
	captionText?: string;
}

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { SortOrder, SortProps, HeadType, RowType, OnSort } from './types';

export const SimpleTable = ({ head, rows, sortKey, sortOrder, onSort, captionText }: Props) => {
	const columnKeys = useMemo(() => head.cells.map((cell) => cell.key), [head]);
	const { formatMessage } = useIntl();

	const alertMessage =
		sortOrder === SortOrder.ASC ? messages.srOnlySortingAlertAsc : messages.srOnlySortingAlertDesc;

	return (
		<Table>
			{captionText ? (
				<SrOnlyCaption>{captionText}</SrOnlyCaption>
			) : (
				<SrOnlyCaption>
					{formatMessage(messages.srOnlyCaption)}
					{sortOrder && (
						<span aria-live="polite" aria-relevant="text">
							{formatMessage(alertMessage, {
								sortKey,
							})}
						</span>
					)}
				</SrOnlyCaption>
			)}
			<TableHead onSort={onSort} {...head} sortKey={sortKey} sortOrder={sortOrder} />
			<tbody>
				{rows.map((rowProps, index) => (
					<TableRow columnKeys={columnKeys} {...rowProps} key={`table-row-${index}`} />
				))}
			</tbody>
		</Table>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Table = styled.table({
	borderCollapse: 'collapse',
	width: '100%',
	tableLayout: 'fixed',
	margin: `0 0 ${token('space.300', '24px')}`,
});

const srOnlyStyles = css({
	position: 'absolute',
	top: 'auto',
	overflow: 'hidden',
	clip: 'rect(1px, 1px, 1px, 1px)',
	width: '1px',
	height: '1px',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const SrOnlyCaption = styled.caption(srOnlyStyles);
