import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	teamManagedBusiness: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-rows.project-type-cell.team-managed-business',
		defaultMessage: 'Team-managed business',
		description:
			'Table cell content displayed in the projects directory type column for team-managed business projects',
	},
	companyManagedBusiness: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-rows.project-type-cell.company-managed-business',
		defaultMessage: 'Company-managed business',
		description:
			'Table cell content displayed in the projects directory type column for classic business projects',
	},
	companyManagedServiceProject: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-rows.project-type-cell.company-managed-service-project',
		defaultMessage: 'Service management',
		description:
			'Table cell content displayed in the projects directory type column for classic service projects',
	},
	teamManagedServiceProject: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-rows.project-type-cell.team-managed-service-project',
		defaultMessage: 'Team-managed service management',
		description:
			'Table cell content displayed in the projects directory type column for next-gen service projects',
	},
	companyManagedSoftware: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-rows.project-type-cell.company-managed-software',
		defaultMessage: 'Company-managed software',
		description:
			'Table cell content displayed in the projects directory type column for classic software projects',
	},
	teamManagedSoftware: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-rows.project-type-cell.team-managed-software',
		defaultMessage: 'Team-managed software',
		description:
			'Table cell content displayed in the projects directory type column for next-gen software projects',
	},
	productDiscoveryProject: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-rows.project-type-cell.product-discovery-project',
		defaultMessage: 'Product Discovery',
		description:
			'Table cell content displayed in the projects directory type column for product discovery/polaris projects',
	},
	customerServiceProjectNonFinal: {
		id: 'projects-directory-v3.page-layout.projects-list.results-table.table-rows.project-type-cell.customer-service-project-non-final',
		defaultMessage: 'Customer service',
		description:
			'Table cell content displayed in the projects directory type column for classic customer service projects',
	},
});
