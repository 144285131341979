import React, { useCallback } from 'react';
import ErrorState from '@atlassian/jira-common-components-error-state';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

export const PageErrorState = () => {
	const { formatMessage } = useIntl();

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const onRetry = useCallback(() => window.location.reload(), []);

	return (
		<ErrorState
			header={formatMessage(messages.header)}
			description={formatMessage(messages.description)}
			onRetry={onRetry}
		/>
	);
};
