import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { Header as DirectoryHeader } from '@atlassian/jira-directory-base-v3/src/ui/header/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { header_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/header_projectsDirectoryV3.graphql';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { CreateButton } from './create-project-action';
import { ImportButton } from './import-project-action';
import messages from './messages';
import { OpenTemplatesPanelButton } from './open-templates-panel';

type Props = {
	dataRef: header_projectsDirectoryV3$key;
	isAdminSettingsContext: boolean;
};

export const Header = ({ dataRef, isAdminSettingsContext }: Props) => {
	const { formatMessage } = useIntl();

	const { jira } = useFragment(
		graphql`
			fragment header_projectsDirectoryV3 on Query
			@argumentDefinitions(
				isAnonymous: { type: "Boolean!" }
				isImporterDiscoveryExperimentEnabled: { type: "Boolean!" }
			) {
				jira {
					...createProjectAction_projectsDirectoryV3_CreateButton
					...openTemplatesPanel_projectsDirectoryV3_OpenTemplatesPanelButton @skip(if: $isAnonymous)
					...importProjectAction_projectsDirectoryV3_ImportButtonWrapper
						@include(if: $isImporterDiscoveryExperimentEnabled)
				}
			}
		`,
		dataRef,
	);

	const isAnonymous = useIsAnonymous();

	return (
		<DirectoryHeader
			title={
				isAdminSettingsContext
					? formatMessage(messages.adminHeaderCaption)
					: formatMessage(messages.headerCaption)
			}
			actions={
				jira ? (
					<>
						<CreateButton dataRef={jira} />
						{!isAdminSettingsContext && fg('jira_importer_projects_directory_cta_gate') && (
							<ImportButton dataRef={jira} />
						)}
						{!isAnonymous && <OpenTemplatesPanelButton dataRef={jira} />}
					</>
				) : undefined
			}
			isAdminSettingsContext={isAdminSettingsContext}
		/>
	);
};
