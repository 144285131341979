import React from 'react';
import {
	SortableColumns,
	TableColumns,
} from '@atlassian/jira-projects-directory-v3-utils/src/constants';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import { StarredHeader } from './content/starred/index.tsx';
import { messages } from './messages';

export const getTableHeader = ({
	formatMessage,
	isAnonymous,
	shouldShowCategories,
	isAdminSettingsContext,
}: {
	formatMessage: FormatMessage;
	isAnonymous: boolean;
	shouldShowCategories: boolean;
	isAdminSettingsContext: boolean;
}) => ({
	cells: [
		{
			key: TableColumns.Favourites,
			content: <StarredHeader label={formatMessage(messages.starred)} />,
			width: 2.85,
			testId: 'jira-project-directory.results-table.header.favourites',
			disabled: isAnonymous,
			isIconColumn: true,
			textAlign: 'center',
		},
		{
			key: TableColumns.Name,
			content: formatMessage(messages.name),
			width: 22,
			testId: 'jira-project-directory.results-table.header.name',
		},
		{
			key: TableColumns.Key,
			content: formatMessage(messages.key),
			width: 12,
			testId: 'jira-project-directory.results-table.header.key',
		},
		{
			key: TableColumns.Type,
			content: formatMessage(messages.type),
			width: 20,
			testId: 'jira-project-directory.results-table.header.type',
		},
		{
			key: TableColumns.Lead,
			content: formatMessage(messages.owner),
			width: shouldShowCategories ? 20 : 36,
			testId: 'jira-project-directory.results-table.header.owner',
		},
		{
			key: TableColumns.Category,
			content: formatMessage(messages.category),
			width: 16,
			testId: 'jira-project-directory.results-table.header.category',
			disabled: !shouldShowCategories,
		},
		{
			key: TableColumns.LastIssueUpdate,
			content: formatMessage(messages.lastIssueUpdate),
			width: 16,
			testId: 'jira-project-directory.results-table.header.last-issue-update',
			disabled: !isAdminSettingsContext,
		},
		{
			key: TableColumns.Url,
			content: formatMessage(messages.projectUrl),
			width: 7,
			testId: 'jira-project-directory.results-table.header.url',
		},
		{
			key: TableColumns.Action,
			content: formatMessage(messages.action),
			width: 8,
			textAlign: 'right',
			testId: 'jira-project-directory.results-table.header.action',
		},
	]
		.filter((cell) => !cell.disabled)
		.map((cell) => ({
			...cell,
			isSortable: SortableColumns.includes(cell.key),
		})),
});
