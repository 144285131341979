import React, { useMemo, useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	FilterFields,
	DEFAULT_LIMIT,
} from '@atlassian/jira-projects-directory-v3-utils/src/constants';
import type { actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef$key } from '@atlassian/jira-relay/src/__generated__/actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef.graphql';
import type { resultsTable_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/resultsTable_projectsDirectoryV3.graphql';
import { SimpleTable } from '@atlassian/jira-simple-table';
import type {
	SortOrder,
	RowType,
	OnSort as OnSortCallback,
} from '@atlassian/jira-simple-table/src/types.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { useFilter } from '../../../../controllers/filter-state';
import messages from './messages';
import { getTableHeader } from './table-head';
import { getTableRow, type projectRefType } from './table-rows';

type Props = {
	shouldShowCategories: boolean;
	isAdminSettingsContext: boolean;
	projectsRef: resultsTable_projectsDirectoryV3$key;
	jiraQueryRef: actionsCell_projectsDirectoryV3_ActionsCellDropdown_jiraQueryRef$key | null;
};

export const ResultsTable = ({
	shouldShowCategories,
	projectsRef,
	jiraQueryRef,
	isAdminSettingsContext,
}: Props) => {
	const { formatMessage } = useIntl();
	const isAnonymous = useIsAnonymous();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({
		action: 'changed',
		actionSubject: 'resultsTable',
	});

	const [filter, { updateFilter }] = useFilter();

	/* eslint-disable @atlassian/relay/must-colocate-fragment-spreads */
	const projects = useFragment(
		graphql`
			fragment resultsTable_projectsDirectoryV3 on JiraProjectConnection
			@argumentDefinitions(isAdminSettingsContext: { type: "Boolean!" }) {
				edges {
					node {
						...favouriteCell_projectsDirectoryV3_FavoriteCell
						...nameCell_projectsDirectoryV3
						...keyCell_projectsDirectoryV3
						...projectTypeCell_projectsDirectoryV3
						...projectCategoryCell_projectsDirectoryV3
						...leadCell_projectsDirectoryV3
						...lastIssueUpdateCell_projectsDirectoryV3 @include(if: $isAdminSettingsContext)
						...urlCell_projectsDirectoryV3
						...actionsCell_projectsDirectoryV3_project
					}
				}
				totalCount
				pageInfo {
					hasNextPage
				}
			}
		`,
		projectsRef,
	);

	const head = useMemo(
		() =>
			getTableHeader({
				formatMessage,
				isAnonymous,
				shouldShowCategories,
				isAdminSettingsContext,
			}),
		[formatMessage, isAnonymous, shouldShowCategories, isAdminSettingsContext],
	);

	const projectsItems = useMemo(() => {
		const totalCount = projects?.totalCount || 0;
		return projects?.pageInfo?.hasNextPage
			? projects?.edges
			: projects?.edges?.slice((totalCount % DEFAULT_LIMIT) * -1);
	}, [projects?.edges, projects?.pageInfo?.hasNextPage, projects?.totalCount]);

	const rows = useMemo(
		() =>
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			projectsItems
				?.map(
					(project) =>
						project &&
						getTableRow(
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							project.node as projectRefType,
							isAdminSettingsContext,
							jiraQueryRef,
						),
				)
				.filter(Boolean) as RowType[],
		[projectsItems, isAdminSettingsContext, jiraQueryRef],
	);

	const handleSort = useCallback<OnSortCallback>(
		({ key, sortOrder }) => {
			updateFilter({
				[FilterFields.SortOrder]: sortOrder,
				[FilterFields.SortKey]: key,
			});
			fireUIAnalytics(analyticsEvent, 'SortOrder', { sortOrder, sortKey: key });
		},
		[analyticsEvent, updateFilter],
	);

	const getCaptionText = () => {
		const selectedProductsKeys = filter[FilterFields.ProjectType]?.split(',').filter(Boolean) || [];
		const selectedProjectCategory = filter[FilterFields.ProjectCategoryLabel];
		const selectedProductsLabels = filter[FilterFields.ProjectTypeLabel];

		if (!selectedProductsKeys.length) {
			if (!shouldShowCategories) {
				return formatMessage(messages.tableCaptionAllProjects);
			}
			if (!selectedProjectCategory) {
				return formatMessage(messages.tableCaptionAllProjectsAndAllCategories);
			}
			return formatMessage(messages.tableCaptionSpecificCategory, {
				category: selectedProjectCategory,
			});
		}
		if (!shouldShowCategories) {
			return formatMessage(messages.tableCaptionSpecificProduct, {
				product: selectedProductsLabels,
			});
		}
		if (!selectedProjectCategory) {
			return formatMessage(messages.tableCaptionSpecificProductAllCategories, {
				product: selectedProductsLabels,
			});
		}
		return formatMessage(messages.tableCaptionSpecificProductSpecificCategories, {
			product: selectedProductsLabels,
			category: selectedProjectCategory,
		});
	};

	return (
		<SimpleTable
			captionText={getCaptionText()}
			head={head}
			rows={rows}
			sortKey={filter[FilterFields.SortKey]}
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			sortOrder={filter[FilterFields.SortOrder] as SortOrder}
			onSort={handleSort}
		/>
	);
};
