import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { RefinementBar as BaseRefinementBar } from '@atlassian/jira-directory-base-v3/src/ui/refinement-bar/index.tsx';
import type { refinementBar_projectsDirectoryV3_Filters$key } from '@atlassian/jira-relay/src/__generated__/refinementBar_projectsDirectoryV3_Filters.graphql';
import { ProjectCategoryPicker } from './project-category-picker';
import { ProjectTypePicker } from './project-type-picker';
import { SearchControl } from './search-control';

type Props = {
	dataRef: refinementBar_projectsDirectoryV3_Filters$key;
};

const Filters = ({ dataRef }: Props) => {
	const data = useFragment(
		graphql`
			fragment refinementBar_projectsDirectoryV3_Filters on Query
			@argumentDefinitions(isAnonymous: { type: "Boolean!" }) {
				jira @optIn(to: "JiraProjectTypes") {
					allJiraProjectCategories(cloudId: $cloudId) @skip(if: $isAnonymous) {
						edges {
							__typename
						}
					}
					...projectCategoryPicker_projectsDirectoryV3 @skip(if: $isAnonymous)
					...projectTypePicker_projectsDirectoryV3
				}
			}
		`,
		dataRef,
	);

	const shouldShowCategories =
		data.jira && Boolean(data.jira?.allJiraProjectCategories?.edges?.length);

	return (
		<>
			<SearchControl />
			{data?.jira && <ProjectTypePicker dataRef={data.jira} />}
			{shouldShowCategories && <ProjectCategoryPicker dataRef={data.jira} />}
		</>
	);
};

export const RefinementBar = ({ dataRef }: Props) => (
	<BaseRefinementBar>
		<Filters dataRef={dataRef} />
	</BaseRefinementBar>
);
