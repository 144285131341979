import React from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import Button from '@atlaskit/button';
import LinkIcon from '@atlaskit/icon/glyph/link';
import Tooltip from '@atlaskit/tooltip';
import type { urlCell_projectsDirectoryV3$key } from '@atlassian/jira-relay/src/__generated__/urlCell_projectsDirectoryV3.graphql';

type Props = {
	project: urlCell_projectsDirectoryV3$key;
};

export const UrlCell = ({ project }: Props) => {
	const data = useFragment(
		graphql`
			fragment urlCell_projectsDirectoryV3 on JiraProject {
				projectUrl
			}
		`,
		project,
	);

	if (!data?.projectUrl) {
		return null;
	}

	const { projectUrl } = data;

	return (
		<UrlCellContent>
			<Tooltip content={projectUrl}>
				<Button
					appearance="subtle-link"
					href={projectUrl}
					target="_blank"
					spacing="none"
					iconBefore={<LinkIcon size="medium" label={projectUrl} />}
				/>
			</Tooltip>
		</UrlCellContent>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const UrlCellContent = styled.div({
	display: 'flex',
	justifyContent: 'center',
});
