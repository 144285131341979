/**
 * @generated SignedSource<<f30ced9f25eaab8d1e39e57e0b3dc8be>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type favouriteCell_directoryBaseV3_FavoriteCell_queryRef$data = {
  readonly id: string;
  readonly isFavourite: boolean | null | undefined;
  readonly name: string;
  readonly " $fragmentType": "favouriteCell_directoryBaseV3_FavoriteCell_queryRef";
};
export type favouriteCell_directoryBaseV3_FavoriteCell_queryRef$key = {
  readonly " $data"?: favouriteCell_directoryBaseV3_FavoriteCell_queryRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"favouriteCell_directoryBaseV3_FavoriteCell_queryRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "favouriteCell_directoryBaseV3_FavoriteCell_queryRef",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "isFavourite"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "600c66fd2f77bc9eec46edf5294a9a60";

export default node;
