import React from 'react';
import { styled } from '@compiled/react';
// eslint-disable-next-line import/order
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import type { SortProps, HeadCellType } from '../../types';
import { HeadCell } from './table-head-cell';

export interface TableHeadProps extends SortProps {
	cells: HeadCellType[];
}

export const TableHead = ({ cells, sortOrder, sortKey, onSort }: TableHeadProps) => (
	<TableHeadContainer>
		<TableHeadRowContainer>
			{cells.map((headCell) => (
				<HeadCell
					{...headCell}
					key={headCell.key}
					onSort={onSort}
					sortOrder={sortOrder}
					sortKey={sortKey}
					cellKey={headCell.key}
				/>
			))}
		</TableHeadRowContainer>
	</TableHeadContainer>
);

const SortIconContainerSelector = 'simple-table-sort-icon-container';
const SortableIconCellContentWrapperSelector = 'simple-table-sortable-icon-cell-content-wrapper';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TableHeadContainer = styled.thead({
	verticalAlign: 'middle',
	border: 'none',
	inset: 0,
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TableHeadRowContainer = styled.tr({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 4.5}px`,
	borderWidth: 'initial',
	borderStyle: 'none',
	borderColor: 'initial',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[`&:hover [data-component-selector="${SortIconContainerSelector}"], &:focus [data-component-selector="${SortIconContainerSelector}"]`]:
		{
			opacity: 0.5,
			marginLeft: token('space.050', '4px'),
		},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[`&:hover [data-component-selector="${SortableIconCellContentWrapperSelector}"], &:focus [data-component-selector="${SortableIconCellContentWrapperSelector}"]`]:
		{
			opacity: 0,
		},
});
