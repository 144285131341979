import { styled } from '@compiled/react';

/**
 * Visible only to screenreaders. Use when there is a need
 * to provide more context to a non-sighted user.
 */
export const visuallyHiddenStyles = {
	clip: 'rect(1px, 1px, 1px, 1px)',
	clipPath: 'inset(50%)',
	height: '1px',
	width: '1px',

	margin: '-1px',
	overflow: 'hidden',
	padding: 0,
	position: 'absolute',
} as const;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const ScreenReaderText = styled.div(visuallyHiddenStyles);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const ScreenReaderCaption = styled.caption(visuallyHiddenStyles);
