import { createStore, createStateHook, createActionsHook } from '@atlassian/react-sweet-state';
import { type State, initialState } from '../common/types';
import { actions, type Actions } from './actions';
import { getReturnFocusTo } from './selectors';

const Store = createStore<State, Actions>({
	name: 'JiraSoftwareModalDialog',
	initialState,
	actions,
});

export const useModalDialogActions = createActionsHook(Store);

export const useReturnFocusTo = createStateHook(Store, {
	selector: getReturnFocusTo,
});
