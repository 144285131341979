/**
 * @generated SignedSource<<7df0b3b5b48dae9d5693144b33eee9ba>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type resultsTable_projectsDirectoryV3$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly " $fragmentSpreads": FragmentRefs<"actionsCell_projectsDirectoryV3_project" | "favouriteCell_projectsDirectoryV3_FavoriteCell" | "keyCell_projectsDirectoryV3" | "lastIssueUpdateCell_projectsDirectoryV3" | "leadCell_projectsDirectoryV3" | "nameCell_projectsDirectoryV3" | "projectCategoryCell_projectsDirectoryV3" | "projectTypeCell_projectsDirectoryV3" | "urlCell_projectsDirectoryV3">;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly pageInfo: {
    readonly hasNextPage: boolean;
  };
  readonly totalCount: number | null | undefined;
  readonly " $fragmentType": "resultsTable_projectsDirectoryV3";
};
export type resultsTable_projectsDirectoryV3$key = {
  readonly " $data"?: resultsTable_projectsDirectoryV3$data;
  readonly " $fragmentSpreads": FragmentRefs<"resultsTable_projectsDirectoryV3">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isAdminSettingsContext"
    }
  ],
  "kind": "Fragment",
  "name": "resultsTable_projectsDirectoryV3",
  "selections": [
    {
      "concreteType": "JiraProjectEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraProject",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "favouriteCell_projectsDirectoryV3_FavoriteCell"
            },
            {
              "kind": "FragmentSpread",
              "name": "nameCell_projectsDirectoryV3"
            },
            {
              "kind": "FragmentSpread",
              "name": "keyCell_projectsDirectoryV3"
            },
            {
              "kind": "FragmentSpread",
              "name": "projectTypeCell_projectsDirectoryV3"
            },
            {
              "kind": "FragmentSpread",
              "name": "projectCategoryCell_projectsDirectoryV3"
            },
            {
              "kind": "FragmentSpread",
              "name": "leadCell_projectsDirectoryV3"
            },
            {
              "condition": "isAdminSettingsContext",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "kind": "FragmentSpread",
                  "name": "lastIssueUpdateCell_projectsDirectoryV3"
                }
              ]
            },
            {
              "kind": "FragmentSpread",
              "name": "urlCell_projectsDirectoryV3"
            },
            {
              "kind": "FragmentSpread",
              "name": "actionsCell_projectsDirectoryV3_project"
            }
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "totalCount"
    },
    {
      "concreteType": "PageInfo",
      "kind": "LinkedField",
      "name": "pageInfo",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "hasNextPage"
        }
      ]
    }
  ],
  "type": "JiraProjectConnection"
};

(node as any).hash = "9ed13593445dfde5eabe8be75addbd2e";

export default node;
