import React, { type ComponentPropsWithRef, forwardRef, type MutableRefObject } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { ProjectArchiveTrigger } from '@atlassian/jira-project-archive-trigger/src/ui/index.tsx';

export const ArchiveProjectDropdownItem = forwardRef(
	(
		props: ComponentPropsWithRef<typeof ProjectArchiveTrigger>,
		ref: MutableRefObject<HTMLElement | null> | ((instance: HTMLElement | null) => void) | null,
	) => (
		<ProjectArchiveTrigger
			{...props}
			Component={({ children }) => (
				<DropdownItem ref={ref} onClick={props.onClick}>
					{children}
				</DropdownItem>
			)}
		/>
	),
);
