/**
 * @generated SignedSource<<d44d33f756a3a4cff1533c4ee4f94ea7>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type favouriteCell_projectsDirectoryV3_FavoriteCell$data = {
  readonly canViewIssue: {
    readonly canPerform: boolean;
  } | null | undefined;
  readonly favouriteValue: {
    readonly " $fragmentSpreads": FragmentRefs<"favouriteCell_directoryBaseV3_FavoriteCellOld_favouriteValueRef">;
  } | null | undefined;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"favouriteCell_directoryBaseV3_FavoriteCell_queryRef">;
  readonly " $fragmentType": "favouriteCell_projectsDirectoryV3_FavoriteCell";
};
export type favouriteCell_projectsDirectoryV3_FavoriteCell$key = {
  readonly " $data"?: favouriteCell_projectsDirectoryV3_FavoriteCell$data;
  readonly " $fragmentSpreads": FragmentRefs<"favouriteCell_projectsDirectoryV3_FavoriteCell">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "favouriteCell_projectsDirectoryV3_FavoriteCell",
  "selections": [
    {
      "alias": "canViewIssue",
      "args": [
        {
          "kind": "Literal",
          "name": "type",
          "value": "VIEW_ISSUES"
        }
      ],
      "concreteType": "JiraProjectAction",
      "kind": "LinkedField",
      "name": "action",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "canPerform"
        }
      ],
      "storageKey": "action(type:\"VIEW_ISSUES\")"
    },
    {
      "kind": "FragmentSpread",
      "name": "favouriteCell_directoryBaseV3_FavoriteCell_queryRef"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "concreteType": "JiraFavouriteValue",
      "kind": "LinkedField",
      "name": "favouriteValue",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "favouriteCell_directoryBaseV3_FavoriteCellOld_favouriteValueRef"
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "name"
    }
  ],
  "type": "JiraProject"
};

(node as any).hash = "ad54d41b3040372f85a46039e18e78ea";

export default node;
