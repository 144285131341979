import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	clear: {
		id: 'searchfield.clear',
		defaultMessage: 'Clear',
		description: 'label which is shown when user hovers of cross icon in searchfield',
	},
	search: {
		id: 'searchfield.search',
		defaultMessage: 'Search',
		description: 'default label when there is no label or placeholder provided',
	},
});
