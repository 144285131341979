/**
 * @generated SignedSource<<9eeddd7d3055f31cacc40c6df5ef698f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type favouriteCell_directoryBaseV3_FavoriteCellOld_favouriteValueRef$data = {
  readonly __id: string;
  readonly isFavourite: boolean | null | undefined;
  readonly " $fragmentType": "favouriteCell_directoryBaseV3_FavoriteCellOld_favouriteValueRef";
};
export type favouriteCell_directoryBaseV3_FavoriteCellOld_favouriteValueRef$key = {
  readonly " $data"?: favouriteCell_directoryBaseV3_FavoriteCellOld_favouriteValueRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"favouriteCell_directoryBaseV3_FavoriteCellOld_favouriteValueRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "favouriteCell_directoryBaseV3_FavoriteCellOld_favouriteValueRef",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isFavourite"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__id"
        }
      ]
    }
  ],
  "type": "JiraFavouriteValue"
};

(node as any).hash = "b3217e19a577d125349014040cc34d96";

export default node;
