/**
 * @generated SignedSource<<f982c84219e38d14c162a90f7e73d838>>
 * @relayHash 932a6ab30b4abea8c4b298d0299ba01a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c9fc45f1ccb390d27ba078874be435f11bb5799b236a50a6fc32b19b5b705f8b

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraProjectListRightPanelState = "CLOSED" | "OPEN" | "%future added value";
export type setRightPanelState_jiraGrowthRecommendationsInProjectList_Mutation$variables = {
  cloudId: string;
  state: JiraProjectListRightPanelState;
};
export type setRightPanelState_jiraGrowthRecommendationsInProjectList_Mutation$data = {
  readonly jira: {
    readonly userPreferences: {
      readonly setProjectListRightPanelState: {
        readonly errors: ReadonlyArray<{
          readonly extensions: {
            readonly statusCode: number | null | undefined;
          } | null | undefined;
          readonly message: string | null | undefined;
        }> | null | undefined;
        readonly projectListRightPanelState: JiraProjectListRightPanelState | null | undefined;
        readonly success: boolean;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type setRightPanelState_jiraGrowthRecommendationsInProjectList_Mutation = {
  response: setRightPanelState_jiraGrowthRecommendationsInProjectList_Mutation$data;
  variables: setRightPanelState_jiraGrowthRecommendationsInProjectList_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "state"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "state",
    "variableName": "state"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "success"
},
v4 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v5 = {
  "kind": "ScalarField",
  "name": "message"
},
v6 = {
  "kind": "ScalarField",
  "name": "projectListRightPanelState"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "setRightPanelState_jiraGrowthRecommendationsInProjectList_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraUserPreferencesMutation",
            "kind": "LinkedField",
            "name": "userPreferences",
            "plural": false,
            "selections": [
              {
                "args": (v2/*: any*/),
                "concreteType": "JiraProjectListRightPanelStateMutationPayload",
                "kind": "LinkedField",
                "name": "setProjectListRightPanelState",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "concreteType": "MutationError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "extensions",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/)
                        ]
                      },
                      (v5/*: any*/)
                    ]
                  },
                  (v6/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "setRightPanelState_jiraGrowthRecommendationsInProjectList_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraUserPreferencesMutation",
            "kind": "LinkedField",
            "name": "userPreferences",
            "plural": false,
            "selections": [
              {
                "args": (v2/*: any*/),
                "concreteType": "JiraProjectListRightPanelStateMutationPayload",
                "kind": "LinkedField",
                "name": "setProjectListRightPanelState",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "concreteType": "MutationError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "extensions",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "__typename"
                          },
                          (v4/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "id"
                              }
                            ],
                            "type": "BulkMutationErrorExtension"
                          }
                        ]
                      },
                      (v5/*: any*/)
                    ]
                  },
                  (v6/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "c9fc45f1ccb390d27ba078874be435f11bb5799b236a50a6fc32b19b5b705f8b",
    "metadata": {},
    "name": "setRightPanelState_jiraGrowthRecommendationsInProjectList_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "1cbcdd010c06eb6470327729729fba37";

export default node;
